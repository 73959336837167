import * as React from 'react'
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import { Layout } from '../components/Layout'
import { Author } from "../components/Author";
import { PrismicRichText, SliceZone } from "@prismicio/react";
import { Tab, Tabs } from "react-bootstrap";
import { components } from "../slices";

const ProductTemplate = ({ data }) => {
  if (!data) return null

  const pageContent = data.prismicProducts
  const product = pageContent.data || {}

  const { lang, type, url } = pageContent
  const alternateLanguages = pageContent.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}

  const ProductImageStyle = {backgroundImage: `url(${product.cover.url})`}

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>

      <div className="Page__Pre bg-primary pt-4">
        <div className="container d-lg-flex justify-content-between py-2 py-lg-3">
          <div className="order-lg-1 pe-lg-4 text-center text-lg-start">
            <h1 className="h3 text-light mb-0 title-font">{product.title}</h1>
          </div>
        </div>
      </div>
      <section className="ProductPage__Card container">
        <div className="bg-white shadow-sm rounded-3 px-4 py-lg-4 py-3 mb-5">
          <div className="py-lg-3 py-2 px-lg-3">
            <div className="row gy-4">
              <div className="col-lg-6">
                <div className="position-relative rounded-3 mb-lg-4 mb-2">
                  <div className="ProductPage__Image rounded-3 shadow" style={ProductImageStyle}></div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="ps-xl-5 ps-lg-3">
                  <h2 className="h3 mb-3">{product.title}</h2>
                  <div className="mb-sm-4 mb-3 fs-sm">
                    <p className="me-sm-3 me-2 text-muted">
                      <Author author={product.author}/>
                    </p>
                    <div>
                      {product.categories.map(({ category }, i)=>{
                        return(
                          <span key={i} className="badge rounded-pill text-bg-light mb-1 me-1">{category}</span>
                        )
                      })}
                    </div>
                  </div>
                  <div className="row row-cols-sm-2 row-cols-1 gy-3 gx-4 mb-4 pb-md-2">
                    <div className="col">
                      <div className="card position-relative h-100 border-0">
                        <div className="card-body p-3 bg-secondary rounded">
                          <h3 className="h6 mb-2 fs-sm text-muted">Collana</h3>
                          <div className="d-flex align-items-center">
                            {product.series ?? "n/a"}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="card position-relative h-100 border-0">
                        <div className="card-body p-3 bg-secondary rounded">
                          <h3 className="h6 mb-2 fs-sm text-muted">Formato</h3>
                          <div className="d-flex align-items-center">
                            {product.format ?? "n/a"}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <a className="btn btn-lg btn-primary d-block w-100 mb-4 animate__animated animate__headShake animate__delay-3s" href={product.external_link.url ?? "#"}>
                    {product.cta_label}
                  </a>

                  <div className="pt-3">
                    <div className="mb-4">

                      <Tabs
                        defaultActiveKey="description"
                        className="mb-3"
                        variant={'tabs'}
                        justify
                      >
                        <Tab eventKey="description" title="Descrizione">
                          <div className="fs-sm">
                            <PrismicRichText field={product.short_description.richText} />
                          </div>
                        </Tab>
                        <Tab eventKey="details" title="Dettagli">
                          <ul className="list-unstyled mb-0">
                            <li
                              className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                              <span>ISBN</span>
                              <div>
                                <span className="fw-medium text-body">{product.isbn}</span>
                              </div>
                            </li>
                            <li
                              className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                              <span>Pagine</span>
                              <div>
                                <span className="text-body">{product.total_pages}</span>
                              </div>
                            </li>
                            <li
                              className="d-flex flex-sm-row flex-column align-items-sm-center justify-content-between mb-2 fs-sm">
                              <span>Formato</span>
                              <div><span className="text-body">{product.format ?? "n/a"}</span></div>
                            </li>
                          </ul>
                        </Tab>
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <SliceZone slices={product.body} components={components} />
    </Layout>
  )
}

export const query = graphql`
  query productQuery($id: String, $lang: String) {
    prismicProducts(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        author
        title
        total_pages
        format
        isbn
        series
        categories {
          category
        }
        cta_label
        external_link {
          url
        }
        short_description {
          richText
        }
        cover {
          url
        }
        body {
          ... on PrismicSliceType {
            id
            slice_label
            slice_type
          }
          ...ProductsDataBodyVimeoVideo
        }
      }
    }
    prismicTopMenu(lang: { eq: $lang }) {
      ...TopMenuFragment
    }
  }
`

export default withPrismicPreview(ProductTemplate)
